export const tabBakcgroundImagesProperty = [
  require('@/static/papertest/bg-1@2x.png'),
  require('@/static/papertest/bg-2@2x.png'),
  require('@/static/papertest/bg-3@2x.png'),
  require('@/static/papertest/bg-4@2x.png'),
  require('@/static/papertest/bg-5@2x.png'),
  require('@/static/papertest/bg-6@2x.png'),
  require('@/static/papertest/bg-1@2x.png'),
  require('@/static/papertest/bg-2@2x.png'),
  require('@/static/papertest/bg-3@2x.png'),
  require('@/static/papertest/bg-4@2x.png'),
  require('@/static/papertest/bg-5@2x.png'),
  require('@/static/papertest/bg-6@2x.png'),
  require('@/static/papertest/bg-1@2x.png'),
  require('@/static/papertest/bg-2@2x.png'),
  require('@/static/papertest/bg-3@2x.png'),
  require('@/static/papertest/bg-4@2x.png'),
  require('@/static/papertest/bg-5@2x.png'),
  require('@/static/papertest/bg-6@2x.png'),
  require('@/static/papertest/bg-1@2x.png'),
  require('@/static/papertest/bg-2@2x.png'),
  require('@/static/papertest/bg-3@2x.png'),
  require('@/static/papertest/bg-4@2x.png'),
  require('@/static/papertest/bg-5@2x.png'),
  require('@/static/papertest/bg-6@2x.png'),
  require('@/static/papertest/bg-1@2x.png'),
  require('@/static/papertest/bg-2@2x.png'),
  require('@/static/papertest/bg-3@2x.png'),
  require('@/static/papertest/bg-4@2x.png'),
  require('@/static/papertest/bg-5@2x.png'),
  require('@/static/papertest/bg-6@2x.png'),
]


export const subjecBackProperty = ['#40B1F5', '#FF6F6F', '#0BC0B1', '#5998ED', '#FFC36F', '#02C9E9', '#40B1F5', '#FF6F6F', '#0BC0B1', '#5998ED', '#FFC36F', '#02C9E9', '#40B1F5', '#FF6F6F', '#0BC0B1', '#5998ED', '#FFC36F', '#02C9E9', '#40B1F5', '#FF6F6F', '#0BC0B1', '#5998ED', '#FFC36F', '#02C9E9','#40B1F5', '#FF6F6F', '#0BC0B1', '#5998ED', '#FFC36F', '#02C9E9']